import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import QuizScene from "./QuizScene";
import StoryScene from "./StoryScene";
import constants from "./constants.json";
var _ = require("lodash");

export default class NilamScene extends Scene {
  constructor(key = "NilamScene") {
    super({ key: key });
  }

  init(data) {
    this.avatar = data.avatar;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
    this.autoPlay = data.autoPlay;
    this.subjectLang = data.subjectLang;
    this.isEvent = data.isEvent;
  }

  create() {
    if (
      store.state.settings.data.audio.sfx == 0 ||
      store.state.settings.data.audio.master == 0
    ) {
      this.sfxVolume = 0.5;
    } else {
      this.sfxVolume =
        store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master;
    }
    this.qTitle = {
      en: {
        text: "Let's Think",
        sfx: "sfx_NS_en",
      },
      zh: {
        text: "想一想",
        sfx: "sfx_NS_zh",
      },
      ms: {
        text: "Jom Fikir",
        sfx: "sfx_NS_ms",
      },
    };

    this.isTranslateON = false;
    this.storyTexts = [];
    this.add.rectangle(240, 427, 480, 854, 0xffffff);
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    if (!this.registry.get("isStartNilamShown")) {
      let temp = this.add
        .text(240, 350, this.qTitle[this.subjectLang].text, {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 48,
          color: "#000",
        })
        .setOrigin(0.5);
      let tempx;

      if (this.subjectLang != i18n.locale) {
        tempx = this.add
          .text(240, 450, this.qTitle[i18n.locale].text, {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 48,
            color: "#000",
          })
          .setOrigin(0.5);
      }

      this.audio = this.sound.add(this.qTitle[this.subjectLang].sfx);
      this.audio.setVolume(this.sfxVolume);
      this.time.delayedCall(500, () => {
        this.audio.play();
      });

      this.audio.on("complete", () => {
        if (this.subjectLang != i18n.locale) {
          this.audio = null;
          this.audio = this.sound.add(this.qTitle[i18n.locale].sfx);
          this.audio.setVolume(this.sfxVolume);
          this.audio.play();
          this.audio.on("complete", () => {
            this.registry.set("isStartNilamShown", true);
            temp.destroy();
            tempx.destroy();
            this.audio = null;
            this.showpage();
          });
        } else {
          this.registry.set("isStartNilamShown", true);
          temp.destroy();
          this.audio = null;
          this.showpage();
        }
      });
    } else {
      this.showpage();
    }

    this.events.on("resume", () => {
      if (
        store.state.settings.data.audio.sfx == 0 ||
        store.state.settings.data.audio.master == 0
      ) {
        this.sfxVolume = 0.5;
      } else {
        this.sfxVolume =
          store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master;
      }
      if (this.audio && this.imgPauseCtrlBtn.visible) {
        this.audio.setVolume(this.sfxVolume);
        this.audio.resume();
      }
    });
  }

  showpage() {
    this.toggleText = this.add
      .text(
        225,
        40,
        i18n.t("string.autoplay") +
          "            " +
          i18n.t("miniGames.general.off"),
        {
          fontFamily: "Inter, sans-serif",
          fontSize: 16,
          color: "#000",
        }
      )
      .setOrigin(0.5);

    this.toggleBtnOff = this.add
      .image(240, 40, "imgToggleOffBtn")
      .setScale(0.3)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.autoPlayON, this);
    this.toggleBtnOn = this.add
      .image(240, 40, "imgToggleOnBtn")
      .setScale(0.3)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.autoPlayOFF, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.4)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.bookPage = this.add
      .image(
        240,
        280,
        this.currentStep.Page.isSecondaryImg && this.isTranslateON
          ? "imgSecondary" + this.currentStep.Page.key
          : this.currentStep.Page.isPrimaryImg
          ? "imgPrimary" + this.currentStep.Page.key
          : "imgBookNilam"
      )
      .setDisplaySize(420, 420);

    this.imgPrevCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 90 : 120, 780, "imgPrevCtrlBtn")
      .setScale(0.14);

    if (this.currentStep.order == 1) {
      this.imgPrevCtrlBtn.setAlpha(0.3);
    } else {
      this.imgPrevCtrlBtn
        .setInteractive({ cursor: "pointer" })
        .on(
          "pointerdown",
          () => this.prevStep(this.currentStep.conditionKey ?? "success"),
          this
        );
    }
    this.imgPlayCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 190 : 240, 780, "imgPlayCtrlBtn")
      .setInteractive({ useHandCursor: true })
      .setScale(0.18)
      .on("pointerdown", () => this.play(), this);
    this.imgPauseCtrlBtn = this.add
      .image(
        this.subjectLang != i18n.locale ? 190 : 240,
        780,
        "imgPauseCtrlBtn"
      )
      .setInteractive({ useHandCursor: true })
      .setScale(0.18)
      .on("pointerdown", this.pause, this);
    this.imgPauseCtrlBtn.visible = false;

    if (this.subjectLang != i18n.locale) {
      this.imgTranslateCtrlBtn = this.add
        .image(290, 780, "imgTranslateCtrlBtn")
        .setScale(0.18)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.translateX, this);
    }

    this.imgNextCtrlBtn = this.add
      .image(this.subjectLang != i18n.locale ? 390 : 360, 780, "imgNextCtrlBtn")
      .setScale(0.14)
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        () => this.nextStep(this.currentStep.conditionKey ?? "success"),
        this
      );

    if (this.autoPlay) {
      this.toggleBtnOff.visible = false;
      this.toggleBtnOn.visible = true;
      this.toggleText.setText(
        i18n.t("string.autoplay") +
          "              " +
          i18n.t("miniGames.general.on")
      );
    } else {
      this.toggleBtnOff.visible = true;
      this.toggleBtnOn.visible = false;
      this.toggleText.setText(
        i18n.t("string.autoplay") +
          "             " +
          i18n.t("miniGames.general.off")
      );
    }

    this.generateStory(this.currentStep.Page);

    this.countdownText = this.add
      .text(this.subjectLang != i18n.locale ? 440 : 420, 780, "", {
        fontFamily: "Inter, sans-serif",
        fontSize: 16,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1)
      .setVisible(false)
      .setInteractive({ useHandCursor: true })
      .on(
        "pointerdown",
        () => {
          if (this.countdownEvent) {
            this.countdownEvent.destroy();
          }
          this.countdownText.setVisible(false);
        },
        this
      );
  }

  translateX() {
    this.stop();
    if (this.countdownEvent) {
      this.countdownEvent.destroy();
    }
    this.countdownText.setVisible(false);

    if (this.isTranslateON) {
      this.isTranslateON = false;
      this.imgTranslateCtrlBtn.clearTint();
    } else {
      this.isTranslateON = true;
      this.imgTranslateCtrlBtn.setTint(0x00ff00);
    }

    this.generateStory(this.currentStep.Page);
  }

  play(isTranslateFirst = false) {
    if (this.countdownEvent) {
      this.countdownEvent.destroy();
    }
    this.countdownText.setVisible(false);
    if (!this.audio) {
      if (isTranslateFirst) {
        this.readStoryAloudX(this.currentStep.Page);
      } else {
        this.readStoryAloud(this.currentStep.Page);
      }
    } else {
      this.audio.setVolume(this.sfxVolume);
      this.audio.resume();
    }
    this.imgPauseCtrlBtn.visible = true;
    this.imgPlayCtrlBtn.visible = false;
  }

  pause() {
    if (this.audio && this.audio.isPlaying) {
      this.audio.pause();
    }
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;
  }

  stop() {
    if (this.audio && this.audio.isPlaying) {
      this.audio.stop();
      this.audio = null;
    }
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;
  }

  resume() {
    if (this.audio && !this.audio.isPlaying) {
      this.audio.setVolume(this.sfxVolume);
      this.audio.resume();
    }
    this.imgPauseCtrlBtn.visible = true;
    this.imgPlayCtrlBtn.visible = false;
  }

  generateStory(story) {
    this.storyTexts.forEach((element) => {
      element.destroy();
    });
    this.storyTexts = [];

    let storyText = JSON.parse(story.translatableContent)[this.subjectLang];
    let processedText = storyText;

    let currentY = this.renderHtmlString(this, processedText, 30, 500, 420);

    if (this.isTranslateON) {
      let secondaryStoryText = JSON.parse(story.translatableContent)[
        i18n.locale
      ];

      let secondaryProcessedText = secondaryStoryText;

      this.renderHtmlString(
        this,
        secondaryProcessedText,
        30,
        currentY + 50,
        420
      );
    }

    if (this.autoPlay) {
      this.time.delayedCall(1000, () => {
        this.play(this.isTranslateON);
      });
    }
  }

  readStoryAloudX(story) {
    if (story.isSecondarySfx) {
      this.audio = this.sound.add("sfxSecondary" + story.key);
      this.audio.setVolume(this.sfxVolume);
      this.audio.play();
      this.audio.on("complete", () => {
        this.audio = null;
        this.stopAudio();
      });
    } else {
      this.stopAudio();
    }
  }

  readStoryAloud(story) {
    if (story.isPrimarySfx) {
      this.audio = this.sound.add("sfxPrimary" + story.key);
      this.audio.setVolume(this.sfxVolume);
      this.audio.play();

      this.audio.on("complete", () => {
        this.audio = null;
        if (story.isSecondarySfx && this.isTranslateON) {
          this.audio = this.sound.add("sfxSecondary" + story.key);
          this.audio.setVolume(this.sfxVolume);
          this.audio.play();

          this.audio.on("complete", () => {
            this.audio = null;

            this.stopAudio();
          });
        } else {
          this.stopAudio();
        }
      });
    } else {
      this.stopAudio();
    }
  }

  stopAudio() {
    this.imgPauseCtrlBtn.visible = false;
    this.imgPlayCtrlBtn.visible = true;

    if (this.autoPlay) {
      let countdown = constants.AUTO_PLAY_COUNTER;
      this.countdownText.setVisible(true);
      this.countdownText.setText(countdown.toString());

      this.countdownEvent = this.time.addEvent({
        delay: 1000,
        callback: () => {
          countdown--;
          this.countdownText.setText(countdown.toString());
          if (countdown <= 0) {
            this.countdownEvent.remove(false);
            this.countdownText.setVisible(false);

            this.nextStep(this.currentStep.conditionKey ?? "success");
          }
        },
        loop: true,
      });
    }
  }

  async nextStep(conditionKey) {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();
    if (this.currentStep.actionType == "restartChapter") {
      this.scene.stop();
      router.push({
        name: this.isEvent ? "PageEventABCBookFailed" : "PageABCBookFailed",
        params: {
          bookKey: this.steps[0].Chapter.bookKey,
          chapterKey: this.steps[0].Chapter.key,
        },
      });
    } else {
      let nextStep = this.steps.findLast(
        (step) => step.order === this.currentStep.order + 1
      );

      if (nextStep) {
        if (nextStep.conditionKey) {
          nextStep = this.steps.findLast(
            (step) =>
              step.order === this.currentStep.order + 1 &&
              step.conditionKey == conditionKey
          );
        }

        let sceneObject = {
          avatar: this.avatar,
          autoPlay: this.autoPlay,
          steps: this.steps,
          currentStep: nextStep,
          subjectLang: this.subjectLang,
          isEvent: this.isEvent,
        };

        if (
          nextStep.actionType == "goPage" ||
          nextStep.actionType == "restartChapter"
        ) {
          let newSceneName = "StoryScene" + Date.now();
          let newScene = await new StoryScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }

        if (nextStep.actionType == "goMap")
          this.scene.start("MapScene", sceneObject);

        if (nextStep.actionType == "goGame")
          this.scene.start("GameScene", sceneObject);

        if (nextStep.actionType == "goItem")
          this.scene.start("ItemScene", sceneObject);

        if (nextStep.actionType == "goQuestion") {
          sceneObject.currentProggressStep = 0;

          let newSceneName = "QuizScene" + Date.now();
          let newScene = await new QuizScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }

        if (nextStep.actionType == "goReflection") {
          let newSceneName = "NilamScene" + Date.now();
          let newScene = await new NilamScene(newSceneName);
          this.scene.add(newSceneName, newScene);
          this.scene.launch(newSceneName, sceneObject);
          this.scene.bringToTop();
          // this.scene.get(newSceneName).cameras.main.scrollX =
          //   -this.game.config.width / 2;

          this.cameras.main.pan(
            this.cameras.main.width * 1.5,
            this.cameras.main.height / 2,
            1000,
            "Power2"
          );

          // this.scene
          //   .get(newSceneName)
          //   .cameras.main.pan(
          //     this.scene.get(newSceneName).cameras.main.width / 2,
          //     this.scene.get(newSceneName).cameras.main.height / 2,
          //     1000,
          //     "Power2"
          //   );

          this.time.delayedCall(1000, () => {
            this.scene.stop();
          });
        }
      } else {
        this.scene.stop();
        router.push({
          name: this.isEvent
            ? "PageEventABCBookCompleted"
            : "PageABCBookCompleted",
          params: {
            bookKey: this.steps[0].Chapter.bookKey,
            chapterKey: this.steps[0].Chapter.key,
          },
        });
      }
    }
  }

  async prevStep(conditionKey) {
    this.imgNextCtrlBtn.removeInteractive();
    this.imgPrevCtrlBtn.removeInteractive();
    this.imgPlayCtrlBtn.removeInteractive();
    this.imgPauseCtrlBtn.removeInteractive();

    this.time.removeAllEvents();
    this.sound.stopAll();
    let nextStep = this.steps.findLast(
      (step) => step.order === this.currentStep.order - 1
    );

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order - 1 &&
            step.conditionKey == conditionKey
        );
      }

      let sceneObject = {
        avatar: this.avatar,
        autoPlay: this.autoPlay,
        steps: this.steps,
        currentStep: nextStep,
        subjectLang: this.subjectLang,
        isEvent: this.isEvent,
      };

      if (nextStep.actionType == "goPage") {
        let newSceneName = "StoryScene" + Date.now();

        let newScene = await new StoryScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", sceneObject);

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", sceneObject);

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", sceneObject);

      if (nextStep.actionType == "goQuestion") {
        sceneObject.currentProggressStep = nextStep.Page.length - 1;

        let newSceneName = "QuizScene" + Date.now();

        let newScene = await new QuizScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }

      if (nextStep.actionType == "goReflection") {
        let newSceneName = "NilamScene" + Date.now();
        let newScene = await new NilamScene(newSceneName);
        this.scene.add(newSceneName, newScene, false, sceneObject);
        this.scene.launch(newSceneName, sceneObject);

        this.scene.get(newSceneName).cameras.main.scrollX =
          this.game.config.width;

        // this.cameras.main.pan(
        //   -this.cameras.main.width /2,
        //   this.cameras.main.height / 2,
        //   1000,
        //   "Power2"
        // );

        this.scene
          .get(newSceneName)
          .cameras.main.pan(
            this.scene.get(newSceneName).cameras.main.width / 2,
            this.scene.get(newSceneName).cameras.main.height / 2,
            1000,
            "Power2"
          );

        this.time.delayedCall(1000, () => {
          this.scene.stop();
        });
      }
    } else {
      this.scene.stop();
      router.push({
        name: this.isEvent ? "PageEventABCBookChapters" : "PageABCBookChapters",
        params: { bookKey: this.steps[0].Chapter.bookKey },
      });
    }
  }

  autoPlayOFF() {
    this.toggleBtnOff.visible = true;
    this.toggleBtnOn.visible = false;
    this.toggleText.setText(
      i18n.t("string.autoplay") +
        "            " +
        i18n.t("miniGames.general.off")
    );
    this.autoPlay = false;
    this.stop();
    if (this.countdownEvent) {
      this.countdownEvent.destroy();
    }
    this.countdownText.setVisible(false);
    let tempSettings = _.cloneDeep(store.state.settings.data);
    tempSettings.autoPlay = "OFF";
    store.commit("updateSettings", tempSettings);
  }

  autoPlayON() {
    this.toggleBtnOff.visible = false;
    this.toggleBtnOn.visible = true;
    this.toggleText.setText(
      i18n.t("string.autoplay") +
        "              " +
        i18n.t("miniGames.general.on")
    );
    this.autoPlay = true;
    this.play();
    let tempSettings = _.cloneDeep(store.state.settings.data);
    tempSettings.autoPlay = "ON";
    store.commit("updateSettings", tempSettings);
  }

  clickClose() {
    this.sound.stopAll();
    this.simpleClickSound.setVolume(this.sfxVolume);
    this.simpleClickSound.play();
    this.scene.stop();
    router.push({
      name: this.isEvent ? "PageEventABCBookChapters" : "PageABCBookChapters",
      params: { bookKey: this.steps[0].Chapter.bookKey },
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(this.sfxVolume);
    this.simpleClickSound.play();
    if (this.audio) this.audio.pause();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: this.scene.key,
      bookKey: this.steps[0].Chapter.bookKey,
      isEvent: this.isEvent,
    });
  }

  update() {}

  renderHtmlString(scene, htmlString, x, y, maxWidth) {
    const style = {
      fontFamily: "Inter, sans-serif",
      fontSize: "18px",
      color: "#000000",
    };

    const vocabTextStyle = {
      fontFamily: "Inter, sans-serif",
      fontSize: "18px",
      // color: "#22c55e ",
      color: "#8E24AA",
      fontStyle: "bold",
    };

    const isMandarin = (text) => {
      // Check if the string contains Chinese characters using a regex
      return /[\u4e00-\u9fff]/.test(text);
    };

    const splitMixedText = (text) => {
      // Updated regex to split mixed Mandarin, English, and punctuation
      const regex =
      /[\u4e00-\u9fff]+[，。、！？：]*|[a-zA-Z0-9]+[.,!?'"“”‘’;:…*/&%$#@^|~`<>[\](){}\\+=_：—–-]*|[.,!?'"“”‘’;:…*/&%$#@^|~`<>[\](){}\\+=_：—–-]|[\s]+/g;
      return text.match(regex) || [];
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const paragraph = doc.querySelector("p");

    if (paragraph) {
      let currentX = x;
      let currentY = y;
      let lineContentWidth = 0; // To track the width of the current line
      const lines = []; // Store each line's text objects for later centering
      const vocabElements = []; // Store vocab elements for underlining

      paragraph.childNodes.forEach((node) => {
        if (node.nodeType === 3) {
          // TEXT_NODE
          // Split mixed English, Mandarin, and punctuation text
          const parts = splitMixedText(node.textContent);

          parts.forEach((part) => {
            const tempText = scene.add.text(0, 0, part, style);
            const partWidth = tempText.width;
            tempText.destroy();

            // Check if the word/character exceeds maxWidth
            if (lineContentWidth + partWidth > maxWidth) {
              lines.push({ width: lineContentWidth, y: currentY }); // Store the line width and y position
              currentY += 30; // Move down to the next line
              lineContentWidth = 0; // Reset the line width
              currentX = x; // Reset to start of the line
            }

            // Add the word/character/punctuation as a text object
            this.storyTexts.push(
              scene.add.text(currentX, currentY, part, style)
            );
            lineContentWidth += partWidth; // Update the line width
            currentX += partWidth; // Move to the next position
          });
        } else if (node.nodeType === 1 && node.nodeName === "VOCAB") {
          const vocabTextNode = node.textContent.trim();
          const vocabKey = node.getAttribute("key");
          const tempText = scene.add.text(0, 0, vocabTextNode, vocabTextStyle);
          const vocabTextWidth = tempText.width;
          tempText.destroy();

          const prevNode = node.previousSibling;
          const nextNode = node.nextSibling;
          const isMandarinPrev =
            prevNode && isMandarin(prevNode.textContent.trim());
          const isMandarinNext =
            nextNode && isMandarin(nextNode.textContent.trim());

          // Add space only if it's English and doesn't have space before the vocab
          if (
            prevNode &&
            !isMandarinPrev &&
            !prevNode.textContent.endsWith(" ")
          ) {
            currentX += 5; // Add a space for English before vocab
          }

          // Check if adding vocab text exceeds maxWidth
          if (lineContentWidth + vocabTextWidth > maxWidth) {
            lines.push({ width: lineContentWidth, y: currentY }); // Store the line width and y position
            currentY += 30; // Move down to the next line
            lineContentWidth = 0; // Reset the line width
            currentX = x; // Reset to start of the line
          }

          // Add vocab text
          const vocabText = scene.add.text(
            currentX,
            currentY,
            vocabTextNode,
            vocabTextStyle
          );
          vocabText.setInteractive({ useHandCursor: true });
          vocabText.on("pointerdown", () => {
            console.log("Vocab clicked:", vocabKey);
          });

          // Store vocab element for underlining later
          vocabElements.push({ text: vocabText, y: currentY });
          this.storyTexts.push(vocabText);

          lineContentWidth += vocabTextWidth; // Update the line width
          currentX += vocabTextWidth; // Move to the next position

          // Add space after vocab if it's English and no space after the vocab
          if (
            nextNode &&
            !isMandarinNext &&
            !nextNode.textContent.startsWith(" ")
          ) {
            currentX += 5; // Add a space for English after vocab
          }
        }
      });

      // Center each line based on stored widths
      lines.forEach(({ width, y }) => {
        const centeredX = x + (maxWidth - width) / 2; // Center the line
        // Update the x position of each text object in that line
        scene.children.each((child) => {
          if (child.y === y) {
            child.x += centeredX - x; // Adjust x position to center
          }
        });
      });

      // Center the last line if it has content
      if (lineContentWidth > 0) {
        const lastLineY = currentY;
        const centeredX = x + (maxWidth - lineContentWidth) / 2; // Center the last line
        scene.children.each((child) => {
          if (child.y === lastLineY) {
            child.x += centeredX - x; // Adjust x position to center
          }
        });
      }

      // Draw underlines after centering
      vocabElements.forEach(({ text, y }) => {
        const underline = scene.add.graphics();
        // underline.lineStyle(2, 0x22c55e);
        underline.lineStyle(2, 0x8e24aa);
        underline.lineBetween(
          text.x,
          y + text.height,
          text.x + text.width,
          y + text.height
        ); // Position underlines correctly
        this.storyTexts.push(underline);
      });

      return currentY;
    }
  }
}
